
import { Vue, Component } from "vue-property-decorator";

import CFileDownload from "@/components/FileDownload.vue";

import mobileAppRoutes from "@/api/routes/mobile_app";

import AppVersion from "../../models/app_version";

@Component({
  components: {
    CFileDownload
  }
})
export default class VAndroidInstallation extends Vue {
  private current_version: AppVersion | null = null;

  private async created() {
    this.$api
      .get(mobileAppRoutes.ios, { params: { os: "Android" } })
      .then(res => (this.current_version = res.data))
      .catch(() => this.$router.push({ name: "unknown" }));
  }
}
